import { FC, useEffect } from 'react';

import styles from './AdminUser.module.scss';
import { IAdminUserFull } from 'services/services.interface';
import { useAppDispatch, useAppSelector } from 'common/hooks';
import { useNavigate, useParams } from 'react-router-dom';
import { setBackBtn } from 'common/utils';
import { getUser } from 'store/reducers/adminSlice';
import { Transaction } from 'components';
import cn from 'classnames';
import { useUtils } from '@telegram-apps/sdk-react';

interface IAdminUser {}

const AdminUser: FC<IAdminUser> = ({}) => {
	const { id } = useParams();
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const utils = useUtils();

	const { user } = useAppSelector((state) => state.admin);

	setBackBtn(() => navigate('/admin'));

	useEffect(() => {
		if (id) dispatch(getUser(+id));
	}, [id]);

	if (!user) return <>Пользователь не найден</>;

	return (
		<div className={styles.wrapper}>
			<div className={styles.title}>Пользователь</div>
			{user.username && (
				<div className={styles.link_wrapper}>
					<button
						onClick={() => utils.openTelegramLink(`https://t.me/${user.username}`)}
						className={styles.link}>
						@{user.username}
					</button>
				</div>
			)}
			<div className={styles.second_title}>Средства</div>
			<div className={styles.value_info}>
				<div className={styles.value_label}>Текущий баланс:</div>
				<div className={styles.value_value}>{user.wallet.availableSum.toFixed(2)} $</div>
			</div>
			<div className={styles.value_info}>
				<div className={styles.value_label}>В стратегиях:</div>
				<div className={styles.value_value}>{user.values.holdingValue.toFixed(2)} $</div>
			</div>
			<br />
			<div className={styles.value_info}>
				<div className={styles.value_label}>Пополнений:</div>
				<div className={styles.value_value}>{user.values.inputValue.toFixed(2)} $</div>
			</div>
			<div className={styles.value_info}>
				<div className={styles.value_label}>Вывод:</div>
				<div className={styles.value_value}>{user.values.outputValue.toFixed(2)} $</div>
			</div>
			<div className={styles.value_info}>
				<div className={styles.value_label}>Заработано:</div>
				<div className={cn(styles.value_value, styles.green)}>
					{user.values.earningsValue.toFixed(2)} $
				</div>
			</div>
			<div className={styles.second_title}>Транзакции</div>
			{user.transactions.length === 0 ? (
				<>Нет данных</>
			) : (
				<div className={styles.trans_list}>
					{user.transactions.map((trans) => (
						<Transaction key={trans.id} data={trans} />
					))}
				</div>
			)}
		</div>
	);
};

export default AdminUser;
