import { FC, ReactNode } from 'react';

import { CloseIcon } from 'icons';

import styles from './ActionPopup.module.scss';
import cn from 'classnames';

interface IActionPopup {
	children?: ReactNode;
	show: boolean;
	onClose: () => void;
}

const ActionPopup: FC<IActionPopup> = ({ children, onClose, show }) => {
	return (
		<div className={cn(styles.wrapper, { [styles.show]: show })}>
			<div className={styles.content}>
				{children}
				<div onClick={onClose} className={styles.close}>
					<CloseIcon />
				</div>
			</div>
		</div>
	);
};

export default ActionPopup;
