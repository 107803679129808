import { SVGProps } from 'react';

const CircleInfoIcon = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={24}
		height={24}
		viewBox="0 0 24 24"
		fill="none"
		{...props}>
		<path
			stroke="var(--hint-color)"
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth={1.5}
			d="M12 22c5.5 0 10-4.5 10-10S17.5 2 12 2 2 6.5 2 12s4.5 10 10 10ZM12 8v5"
		/>
		<path
			stroke="var(--hint-color)"
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth={2}
			d="M11.994 16h.01"
		/>
	</svg>
);
export default CircleInfoIcon;
