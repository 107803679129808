import { SVGProps } from 'react';

const WalletCheckIcon = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={25}
		height={24}
		viewBox="0 0 25 24"
		fill="none"
		{...props}>
		<path
			stroke="var(--btn-color)"
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeMiterlimit={10}
			strokeWidth={1.5}
			d="M9.5 19c0 .75-.21 1.46-.58 2.06A3.97 3.97 0 0 1 5.5 23a3.97 3.97 0 0 1-3.42-1.94A3.92 3.92 0 0 1 1.498 19c0-2.21 1.79-4 4-4s4 1.79 4 4Z"
		/>
		<path
			stroke="var(--btn-color)"
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth={1.5}
			d="m3.94 19 .99.99 2.13-1.97M18.251 7.05c-.24-.04-.49-.05-.75-.05h-10c-.28 0-.55.02-.81.06.14-.28.34-.54.58-.78l3.25-3.26a3.525 3.525 0 0 1 4.96 0l1.75 1.77c.64.63.98 1.43 1.02 2.26Z"
		/>
		<path
			stroke="var(--btn-color)"
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth={1.5}
			d="M22.5 12v5c0 3-2 5-5 5H8.13c.31-.26.58-.58.79-.94.37-.6.58-1.31.58-2.06a3.999 3.999 0 0 0-7.001-2.64V12c0-2.72 1.64-4.62 4.19-4.94.26-.04.53-.06.81-.06h10c.26 0 .51.01.75.05C20.83 7.35 22.5 9.26 22.5 12Z"
		/>
		<path
			stroke="var(--btn-color)"
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth={1.5}
			d="M22.5 12.5h-3c-1.1 0-2 .9-2 2s.9 2 2 2h3"
		/>
	</svg>
);
export default WalletCheckIcon;
