import { FC, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import {
	getOutputRequests,
	getUpcomingFinish,
	getWallets,
} from 'store/reducers/adminSlice';
import { useAppDispatch, useAppSelector } from 'common/hooks';
import { setBackBtn } from 'common/utils';
import { Button } from 'components';

import styles from './Admin.module.scss';

const Admin: FC = () => {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	setBackBtn(() => navigate('/'));

	const { outputReqs, upcomingFinish, mainWallets } = useAppSelector(
		(state) => state.admin
	);

	useEffect(() => {
		dispatch(getOutputRequests());
		dispatch(getUpcomingFinish());
		dispatch(getWallets());
	}, []);

	const upcomingFinishKeys = Object.keys(upcomingFinish);

	const links: {
		label: string;
		link: string;
		count?: number;
	}[] = [
		{
			label: 'Стратегии',
			link: 'strategies',
		},
		{
			label: 'Пользователи',
			link: 'users',
		},
		{
			label: 'Запросы',
			link: 'outputs',
			count: outputReqs.length,
		},
		{
			label: 'Настройки',
			link: 'settings',
		},
	];

	return (
		<div className={styles.wrapper}>
			<div className={styles.title}>АДМИНКА</div>
			<a href={process.env.REACT_APP_API_URL}>DEV</a>
			<div className={styles.list}>
				{links.map((el) => (
					<Button
						className={styles.button}
						key={el.label}
						onClick={() => navigate(`/admin/${el.link}`)}>
						{el.label}
						{!!el.count && <span className={styles.count}>{el.count}</span>}
					</Button>
				))}
			</div>
			<div>
				<div className={styles.header}>
					<div className={styles.name}>Баланс кошельков</div>
					<Link to="/admin/wallets">Подробнее</Link>
				</div>
				{mainWallets.length > 0 && (
					<div className={styles.upcoming}>
						<div className={styles.balance_item}>
							<div className={styles.balance}>Баланс USDT:</div>
							<div className={styles.value}>
								{mainWallets.reduce((prev, el) => prev + el.usdtBalance, 0).toFixed(2)}{' '}
								USDT
							</div>
						</div>
						<div className={styles.balance_item}>
							<div className={styles.balance}>Баланс TRX:</div>
							<div className={styles.value}>
								{mainWallets.reduce((prev, el) => prev + el.trxBalance, 0).toFixed(2)} TRX
							</div>
						</div>
					</div>
				)}
			</div>
			{upcomingFinishKeys.length > 0 && (
				<div>
					<div className={styles.name}>Ближайшие завершения</div>
					<div className={styles.upcoming}>
						{upcomingFinishKeys.map((el) => (
							<div className={styles.upcoming_item}>
								<div className={styles.date}>{el}</div>
								<div className={styles.value}>{upcomingFinish[el].toFixed()} USDT</div>
							</div>
						))}
					</div>
				</div>
			)}
		</div>
	);
};

export default Admin;
