import { FC, useEffect, useRef, useState } from 'react';

import styles from './CopiedPopup.module.scss';
import cn from 'classnames';
import { CopiedIcon } from 'icons';

interface ICopiedPopup {
	show: boolean | number;
}

const CopiedPopup: FC<ICopiedPopup> = ({ show }) => {
	const ref = useRef<HTMLDivElement>(null);
	const [animNum, setAnimNum] = useState(0);

	useEffect(() => {
		if (typeof show === 'boolean') return;

		ref.current?.classList.remove('vibro_anim');

		if (animNum < show && show !== 1) {
			setTimeout(() => {
				ref.current?.classList.add('vibro_anim');
			}, 100);
		}

		setTimeout(() => {
			setAnimNum(show);
		}, 300);
	}, [show]);

	return (
		<div ref={ref} className={cn(styles.wrapper, { [styles.opened]: !!show })}>
			<CopiedIcon />
			<p>Address is copied to clipboard.</p>
		</div>
	);
};

export default CopiedPopup;
