import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { AsyncThunkConfig } from 'common/interfaces';
import { strategyService } from 'services/services';
import { ICreateStrategy, IStrategy } from 'services/services.interface';

interface IStrategyInitialState {
	strategies: IStrategy[];
}

const initialState: IStrategyInitialState = {
	strategies: [],
};

export const strategySlice = createSlice({
	name: 'strategy',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(getStrategies.fulfilled, (state, { payload }) => {
			state.strategies = payload;
		});
		builder.addCase(createStrategy.fulfilled, (state, { payload }) => {
			state.strategies.push(payload);
		});
		builder.addCase(deleteStrategy.fulfilled, (state, { payload }) => {
			state.strategies = state.strategies.filter((s) => s.id !== payload);
		});
	},
});

export const strategyReducer = strategySlice.reducer;
// export const { * } = strategySlice.actions;

export const getStrategies = createAsyncThunk<IStrategy[], void, AsyncThunkConfig>(
	'strategy/getStrategies',
	async (_, { rejectWithValue }) => {
		try {
			return await strategyService.getAll();
		} catch (error) {
			return rejectWithValue('[getStrategies]: Error');
		}
	}
);

export const createStrategy = createAsyncThunk<
	IStrategy,
	ICreateStrategy,
	AsyncThunkConfig
>('strategy/createStrategy', async (data, { rejectWithValue }) => {
	try {
		return await strategyService.create(data);
	} catch (error) {
		return rejectWithValue('[createStrategy]: Error');
	}
});

export const deleteStrategy = createAsyncThunk<number, number, AsyncThunkConfig>(
	'strategy/deleteStrategy',
	async (id, { rejectWithValue }) => {
		try {
			await strategyService.delete(id);
			return id;
		} catch (error) {
			return rejectWithValue('[deleteStrategy]: Error');
		}
	}
);
