import { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from 'common/hooks';
import { createWallet, getWallets } from 'store/reducers/adminSlice';
import { setBackBtn } from 'common/utils';
import { Button } from 'components';

import styles from './AdminWallets.module.scss';
import { useUtils } from '@telegram-apps/sdk-react';

interface IAdminWallets {}

const AdminWallets: FC<IAdminWallets> = ({}) => {
	const utils = useUtils();
	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	setBackBtn(() => navigate('/admin'));

	const { mainWallets, loading } = useAppSelector((state) => state.admin);

	useEffect(() => {
		dispatch(getWallets());
	}, []);

	const createHandler = () => {
		if (loading === 'WAIT') {
			dispatch(createWallet());
		}
	};

	return (
		<div className={styles.wrapper}>
			<div className={styles.header}>
				<div className={styles.title}>Кошельки</div>
				<Button disabled={loading !== 'WAIT'} size="small" onClick={createHandler}>
					Добавить
				</Button>
			</div>
			<div className={styles.list}>
				{mainWallets.map((el) => (
					<div key={el.address} className={styles.item}>
						<div
							onClick={() =>
								utils.openLink(`${process.env.REACT_APP_TRON_URL}/#/address/${el.address}`)
							}
							className={styles.address}>
							{el.address}
						</div>
						<div className={styles.balance}>{el.usdtBalance.toFixed(2)} USDT</div>
						<div className={styles.balance}>{el.trxBalance.toFixed(2)} TRX</div>
					</div>
				))}
			</div>
		</div>
	);
};

export default AdminWallets;
