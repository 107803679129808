import { FC } from 'react';
import cn from 'classnames';

import styles from './Loader.module.scss';

interface ILoader {
	size?: 'small' | 'default';
}

const Loader: FC<ILoader> = ({ size = 'default' }) => {
	return <div className={cn(styles.loader, { [styles.small]: size === 'small' })}></div>;
};

export default Loader;
