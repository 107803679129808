import { FC } from 'react';

import robotImg from 'assets/img/robot.png';

import styles from './Info.module.scss';
import { ArrowIcon } from 'icons';

interface IInfo {}

const Info: FC<IInfo> = ({}) => {
	return (
		<div className={styles.wrapper}>
			<div className={styles.content}>
				<p>Up to 400% annual return on trading robots!</p>
				<div className={styles.link}>
					<span>About trading robots</span>
					<ArrowIcon />
				</div>
			</div>
			<div className={styles.image}>
				<img src={robotImg} alt="robot" />
			</div>
		</div>
	);
};

export default Info;
