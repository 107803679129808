import { FC, forwardRef } from 'react';
import { InputProps } from 'react-html-props';
import cn from 'classnames';

import styles from './Input.module.scss';

interface IInput extends InputProps {
	error?: string;
	rightLabel?: string;
}

const Input: FC<IInput> = forwardRef(
	({ className, error, rightLabel, ...restProps }, ref) => {
		const classNames = cn(styles.input, className, { [styles.error]: error });

		return (
			<div className={styles.wrapper}>
				<div className={styles.right_label_wrapper}>
					<input className={classNames} {...restProps} ref={ref} />
					{rightLabel && <div className={styles.right_label}>{rightLabel}</div>}
				</div>
				{error && <div className={styles.error_message}>{error}</div>}
			</div>
		);
	}
);

export default Input;
