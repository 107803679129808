import { useSelector, useDispatch, TypedUseSelectorHook } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { RootDispatchType, RootStateType } from 'store/createStore';

export const useAppDispatch: () => RootDispatchType = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootStateType> = useSelector;

export const useAdminGuard = () => {
	const { user } = useAppSelector((state) => state.user);
	const navigate = useNavigate();

	if (user.role === 'USER') {
		navigate('/');
		return false;
	}

	return true;
};
