import { SVGProps } from 'react';

const CopiedIcon = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 17 28"
		width={17}
		height={28}
		fill="none"
		{...props}>
		<path stroke="#fff" strokeWidth={2} d="M12 4H5.4C2.97 4 1 6.09 1 8.667V18" />
		<path
			stroke="#fff"
			strokeWidth={2}
			d="M4 12.783c0-1.469.001-2.49.087-3.27.083-.762.237-1.172.457-1.468.14-.187.301-.354.481-.496.28-.221.665-.375 1.394-.46C7.171 7.002 8.156 7 9.583 7c1.428 0 2.413.002 3.164.089.73.085 1.115.239 1.395.46.18.142.341.309.48.496.221.296.374.706.458 1.468.085.78.087 1.801.087 3.27v4.934c0 1.469-.002 2.49-.087 3.27-.084.762-.237 1.172-.457 1.468-.14.187-.301.354-.481.496-.28.221-.665.375-1.395.46-.751.087-1.736.089-3.164.089-1.427 0-2.412-.002-3.164-.089-.73-.085-1.114-.239-1.394-.46a2.647 2.647 0 0 1-.48-.496c-.221-.296-.375-.706-.458-1.468C4 20.207 4 19.186 4 17.717v-4.934Z"
		/>
		<path fill="#fff" d="M5 8h10v15H5z" />
	</svg>
);
export default CopiedIcon;
