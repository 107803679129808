import { FC, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import cn from 'classnames';

import {
	clearHoldings,
	getHoldings,
	getHoldingsHistory,
	getHoldingsStats,
} from 'store/reducers/holdingSlice';
import {
	Area,
	AreaChart,
	CartesianGrid,
	ResponsiveContainer,
	Tooltip,
	XAxis,
	YAxis,
} from 'recharts';
import { ContentType } from 'recharts/types/component/Tooltip';
import { NameType, ValueType } from 'recharts/types/component/DefaultTooltipContent';
import { useAppDispatch, useAppSelector } from 'common/hooks';
import { setBackBtn } from 'common/utils';
import { Strategies } from 'components';
import { CircleInfoIcon } from 'icons';

import styles from './Holdings.module.scss';
import {
	initClosingBehavior,
	initSwipeBehavior,
	SwipeBehavior,
} from '@telegram-apps/sdk-react';

const filters = [7, 30, 90, 180] as const;

const Holdings: FC = () => {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();

	setBackBtn(() => navigate('/'));

	const ref = useRef<HTMLDivElement>(null);

	const [filter, setFilter] = useState<(typeof filters)[number]>(7);
	const [time, setTime] = useState(Date.now());
	const [activeTab, setActiveTab] = useState<'active' | 'history'>('active');
	const { holdings, history, stats } = useAppSelector((state) => state.holding);

	useEffect(() => {
		dispatch(getHoldings());
		dispatch(getHoldingsHistory());

		const timer = setInterval(() => {
			setTime(Date.now());
		}, 60 * 1000);

		let swipe: SwipeBehavior | null = null;
		try {
			[swipe] = initSwipeBehavior();
			swipe.disableVerticalSwipe();
		} catch {}

		return () => {
			dispatch(clearHoldings());
			clearInterval(timer);

			try {
				swipe?.enableVerticalSwipe();
			} catch {}
		};
	}, [dispatch]);

	useEffect(() => {
		dispatch(getHoldingsStats(filter));
	}, [filter]);

	useEffect(() => {
		if (ref.current) {
			ref.current.addEventListener(
				'touchstart',
				(e) => {
					e.preventDefault();
				},
				{ passive: false }
			);
			ref.current.addEventListener(
				'touchmove',
				(e) => {
					e.preventDefault();
				},
				{ passive: false }
			);
		}
	}, [ref]);

	return (
		<div className={styles.wrapper}>
			<div className={styles.title}>My Strategies</div>
			<div className={styles.content}>
				{holdings.length > 0 && stats && (
					<div className={styles.chart}>
						<div>
							<div className={styles.chart_label}>Total balance</div>
							<div className={styles.chart_value}>{stats.currentBalance} USDT</div>
							<div className={styles.chart_rate}>
								+{((stats.currentResult / stats.currentBalance - 1) * 100).toFixed()}%
							</div>
						</div>

						<ResponsiveContainer
							ref={ref}
							style={{ touchAction: 'none' }}
							width="100%"
							height={85}>
							<AreaChart
								data={stats.historyBalance}
								margin={{ top: 10, right: 10, left: 10, bottom: 10 }}>
								<defs>
									<linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
										<stop offset="5%" stopColor="var(--link-color)" stopOpacity={0.4} />
										<stop offset="110%" stopColor="var(--link-color)" stopOpacity={0} />
									</linearGradient>
								</defs>
								<Tooltip content={CustomTooltip} />
								<Area
									type="monotone"
									dataKey="value"
									stroke="var(--link-color)"
									fillOpacity={1}
									fill="url(#colorPv)"
								/>
								<XAxis dataKey="date" hide={true} />
							</AreaChart>
						</ResponsiveContainer>
						<div className={styles.chart_buttons}>
							{filters.map((el) => (
								<button
									key={el}
									onClick={() => setFilter(el)}
									className={cn(styles.chart_btn, { [styles.active]: el === filter })}>
									{el}d
								</button>
							))}
						</div>
					</div>
				)}
				<div className={styles.holdings}>
					<div className={styles.switchers}>
						<button
							onClick={() => setActiveTab('active')}
							className={cn(styles.switch, { [styles.active]: activeTab === 'active' })}>
							Active Strategies
						</button>
						{history.length !== 0 && (
							<button
								onClick={() => setActiveTab('history')}
								className={cn(styles.switch, {
									[styles.active]: activeTab === 'history',
								})}>
								My History
							</button>
						)}
					</div>
					{activeTab === 'active' ? (
						holdings.length === 0 ? (
							<div className={styles.empty}>
								<CircleInfoIcon />
								<p>You don't have any active strategies yet</p>
							</div>
						) : (
							<div className={styles.list}>
								{holdings.map((el) => {
									const timestamp =
										(new Date(el.createdAt).getTime() +
											el.strategy.days * 24 * 60 * 60 * 1000 -
											time) /
										(1000 * 60);
									const days = Math.floor(timestamp / (60 * 24));
									const hours = Math.floor((timestamp / 60) % 24);
									const minutes = Math.floor(timestamp % 60);

									return (
										<div key={el.id} className={styles.item}>
											<div className={styles.name}>
												<div className={styles.image}></div>
												{el.strategy.name}
											</div>
											<div className={styles.rate}>
												<div>+ {(el.sum * (el.strategy.rate - 1)).toFixed(2)}$</div>
												<div className={styles.dot}></div>
												<div>{((el.strategy.rate - 1) * 100).toFixed(2)}%</div>
											</div>
											<div>
												<div className={styles.info_label}>My Deposit:</div>
												<div className={styles.dep}>{el.sum.toFixed(2)} USDT</div>
											</div>
											{el.isFinished ? (
												<>DONE</>
											) : (
												<div>
													<div className={styles.info_label}>Time remaining</div>
													<div className={styles.date}>
														{days}d. {hours}h. {minutes}min.
													</div>
												</div>
											)}
										</div>
									);
								})}
							</div>
						)
					) : (
						<div className={styles.list}>
							{history.map((el) => {
								return (
									<div key={el.id} className={styles.item}>
										<div className={styles.name}>
											<div className={styles.image}></div>
											{el.strategy.name}
										</div>
										<div className={styles.rate}>
											<div>+ {(el.sum * (el.strategy.rate - 1)).toFixed(2)}$</div>
											<div className={styles.dot}></div>
											<div>{((el.strategy.rate - 1) * 100).toFixed(2)}%</div>
										</div>
										<div>
											<div className={styles.info_label}>Placement period:</div>
											<div className={styles.date}>{el.strategy.days}</div>
										</div>
										<div>
											<div className={styles.info_label}>My Deposit:</div>
											<div className={styles.dep}>{el.sum.toFixed(2)} USDT</div>
										</div>
										<div>
											<div className={styles.info_label}>Total:</div>
											<div className={styles.total}>
												{(el.sum * el.strategy.rate).toFixed(2)} USDT
											</div>
										</div>
									</div>
								);
							})}
						</div>
					)}
				</div>
				<Strategies />
			</div>
		</div>
	);
};

const CustomTooltip: ContentType<ValueType, NameType> = ({ active, payload, label }) => {
	useEffect(() => {
		if (active) {
			window.ontouchmove = (e) => {
				e.stopPropagation();
				e.preventDefault();
			};
		} else {
			window.ontouchmove = null;
		}
	}, [active]);

	if (active) {
		const value =
			payload !== undefined &&
			payload.length > 0 &&
			payload[0].value !== undefined &&
			typeof payload[0].value === 'number'
				? payload[0].value
				: undefined;

		return (
			<div className={styles.tooltip}>
				<h4 className={styles.tooltip_label}>{label}</h4>
				{value !== undefined && (
					<p className={styles.tooltip_value}>{value.toFixed(2)} USDT</p>
				)}
			</div>
		);
	}
	return null;
};

export default Holdings;
