import { SVGProps } from 'react';

const AddIcon = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={24}
		height={24}
		viewBox="0 0 24 24"
		fill="none"
		{...props}>
		<path
			fill="var(--btn-text-color)"
			fillRule="evenodd"
			d="M11.999 21a9 9 0 1 0 0-18 9 9 0 0 0 0 18Zm.75-13a.75.75 0 0 0-1.5 0v6.19l-2.22-2.22a.75.75 0 0 0-1.06 1.06l3.5 3.5a.75.75 0 0 0 1.06 0l3.5-3.5a.75.75 0 0 0-1.06-1.06l-2.22 2.22V8Z"
			clipRule="evenodd"
		/>
	</svg>
);
export default AddIcon;
