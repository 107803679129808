import { SVGProps } from 'react';

const CopyIcon = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={24}
		height={24}
		viewBox="0 0 24 24"
		fill="none"
		{...props}>
		<path
			stroke="var(--btn-text-color)"
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth={1.5}
			d="M16 12.9v4.2c0 3.5-1.4 4.9-4.9 4.9H6.9c-3.5 0-4.901-1.4-4.901-4.9v-4.2c0-3.5 1.4-4.9 4.9-4.9h4.2c3.5 0 4.9 1.4 4.9 4.9Z"
		/>
		<path
			stroke="var(--btn-text-color)"
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth={1.5}
			d="M22 6.9v4.2c0 3.5-1.4 4.9-4.9 4.9H16v-3.1C16 9.4 14.6 8 11.1 8H7.998V6.9c0-3.5 1.4-4.9 4.9-4.9h4.2C20.6 2 22 3.4 22 6.9Z"
		/>
	</svg>
);
export default CopyIcon;
