import { SVGProps } from 'react';

const PlusIcon = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={16}
		height={17}
		viewBox="0 0 16 17"
		fill="none"
		{...props}>
		<path
			stroke="var(--btn-color)"
			strokeLinecap="round"
			strokeLinejoin="round"
			d="M8 15.167c3.666 0 6.666-3 6.666-6.667s-3-6.667-6.666-6.667c-3.667 0-6.667 3-6.667 6.667s3 6.667 6.667 6.667Z"
		/>
		<path
			stroke="var(--btn-color)"
			strokeLinecap="round"
			d="M5.333 8.5h5.333M8 11.166V5.833"
		/>
	</svg>
);
export default PlusIcon;
