import { configureStore } from '@reduxjs/toolkit';

import { appReducer } from './reducers/appSlice';
import { userReducer } from './reducers/userSlice';
import { strategyReducer } from './reducers/strategySlice';
import { holdingReducer } from './reducers/holdingSlice';
import { adminReducer } from './reducers/adminSlice';
import { settingsReducer } from './reducers/settingsSlice';

export const store = configureStore({
	reducer: {
		app: appReducer,
		user: userReducer,
		strategy: strategyReducer,
		holding: holdingReducer,
		admin: adminReducer,
		settings: settingsReducer,
	},
	devTools: process.env.NODE_ENV !== 'production',
});

export type RootStateType = ReturnType<typeof store.getState>;
export type RootDispatchType = typeof store.dispatch;
