import { FC, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { on } from '@telegram-apps/sdk-react';

import { useAppDispatch, useAppSelector } from 'common/hooks';
import { ActionPopup, Button, Input, OutputRequest, Transaction } from 'components';
import { TransStatusEnum } from 'common/interfaces';
import { setBackBtn } from 'common/utils';
import {
	getOutputRequest,
	getTransactions,
	outputRequest,
} from 'store/reducers/userSlice';

import styles from './Send.module.scss';
import { createPortal } from 'react-dom';
import { getSettings } from 'store/reducers/settingsSlice';
import { SuccessIcon } from 'icons';

interface ISend {}

const Send: FC<ISend> = ({}) => {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();

	setBackBtn(() => {
		navigate('/');
	});

	const [sent, setSent] = useState(false);
	const [address, setAddress] = useState('');
	const [sum, setSum] = useState('');

	const { user, wallet, transactions, outputReq } = useAppSelector((state) => state.user);
	const { settings } = useAppSelector((state) => state.settings);

	useEffect(() => {
		dispatch(getTransactions());
		dispatch(getOutputRequest());
		dispatch(getSettings());
	}, [dispatch]);

	const onPopupClose = () => {
		setSent(false);
		setSum('');
		setAddress('');
	};

	const fee = settings.find((el) => el.name === 'output-fee')?.value || 2;

	const isButtonDisabled =
		transactions.findIndex((el) => el.status === TransStatusEnum.WAIT) !== -1 ||
		!!outputReq ||
		sum === '' ||
		Number.isNaN(+sum) ||
		+sum < fee + 1 ||
		+sum > wallet.availableSum;

	const outputAction =
		outputReq?.confirmed === null
			? 'waiting'
			: outputReq?.confirmed === true
			? 'confirmed'
			: 'rejected';

	return (
		<div className={styles.wrapper}>
			{outputReq ? (
				<div className={styles.content}>
					<OutputRequest />
				</div>
			) : (
				<>
					<div className={styles.title}>Enter the USDT TRC-20 </div>
					<div className={styles.info}>
						<p>
							Send only <b>USDT TRC20</b>.
						</p>
						<p>Be careful and check the network type</p>
					</div>
					<div className={styles.content}>
						<Input
							placeholder="USDT TRC-20"
							value={address}
							className={styles.input}
							onChange={(e) => setAddress(e.currentTarget.value)}
						/>
						<Input
							type="number"
							pattern="[0-9]*"
							inputMode="decimal"
							min={fee + 1}
							max={wallet.availableSum}
							placeholder={`Min ${fee + 1} - Max ${wallet.availableSum.toFixed(0)}`}
							value={sum}
							className={styles.input}
							onChange={(e) => setSum(e.currentTarget.value)}
						/>
						<div className={styles.balance}>
							<p>Your Balance</p>
							<p>{wallet.availableSum.toFixed(2)} USDT</p>
						</div>
						<div className={styles.send_block}>
							<Button
								className={styles.button}
								size="small"
								disabled={isButtonDisabled}
								onClick={async () => {
									if (!address || !sum) return;
									if (Number.isNaN(+sum)) return;
									if (+sum > wallet.availableSum) return;

									const actionResult = await dispatch(
										outputRequest({
											address: address,
											sum: +sum,
											userId: user.id,
										})
									);

									if (outputRequest.fulfilled.match(actionResult)) {
										setSent(true);
									}
								}}>
								Send
							</Button>
							<div className={styles.send_block_info}>Payment fee is ${fee}</div>
						</div>
					</div>
				</>
			)}
			{transactions.length > 0 && (
				<div className={styles.content}>
					<div className={styles.name}>RECENT</div>
					<div className={styles.list}>
						{transactions.map((el) => (
							<Transaction data={el} />
						))}
					</div>
				</div>
			)}
			<ActionPopup show={sent} onClose={onPopupClose}>
				<div className={styles.popup}>
					<div className={styles.popup_image}>
						<SuccessIcon />
					</div>
					<div className={styles.popup_title}>A withdrawal request has been formed</div>
					<div className={styles.popup_text}>
						<g>{sum} USDT</g> was sent to the account of{' '}
						<b>
							{address.substring(0, 4) + '***' + address.substring(address.length - 4)}
						</b>{' '}
						Expect a transfer within 24 hours
					</div>
					<Button size="small" onClick={onPopupClose} className={styles.popup_btn}>
						Okey
					</Button>
				</div>
			</ActionPopup>
		</div>
	);
};

export default Send;
