import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { AsyncThunkConfig } from 'common/interfaces';
import { adminService } from 'services/services';
import {
	IAdminUser,
	IAdminUserFull,
	IAdminUsersResponse,
	IConfirmOutputRequest,
	IGetManyUsers,
	IMainWallet,
	IOutputRequest,
	IUpcomingFinish,
} from 'services/services.interface';

interface IAdminInitialState {
	users: IAdminUser[];
	outputReqs: IOutputRequest[];
	user: IAdminUserFull | null;
	usersCount: number;
	upcomingFinish: IUpcomingFinish;
	mainWallets: IMainWallet[];
	loading: 'GET_WALLETS' | 'CREATE_WALLET' | 'WAIT';
}

const initialState: IAdminInitialState = {
	users: [],
	outputReqs: [],
	user: null,
	usersCount: 0,
	upcomingFinish: {},
	mainWallets: [],
	loading: 'WAIT',
};

export const adminSlice = createSlice({
	name: 'admin',
	initialState,
	reducers: {
		resetUsers(state) {
			state.users = [];
		},
	},
	extraReducers: (builder) => {
		builder.addCase(getUsers.fulfilled, (state, { payload }) => {
			state.users = [...state.users, ...payload.result];
			state.usersCount = payload.count;
		});
		builder.addCase(getUser.fulfilled, (state, { payload }) => {
			state.user = payload;
		});
		builder.addCase(getOutputRequests.fulfilled, (state, { payload }) => {
			state.outputReqs = payload;
		});
		builder.addCase(confirmOutputRequests.fulfilled, (state, { payload }) => {
			state.outputReqs = state.outputReqs.filter((el) => el.id !== payload.id);
		});
		builder.addCase(getUpcomingFinish.fulfilled, (state, { payload }) => {
			state.upcomingFinish = payload;
		});

		builder.addCase(getWallets.pending, (state) => {
			state.loading = 'GET_WALLETS';
		});
		builder.addCase(getWallets.fulfilled, (state, { payload }) => {
			state.mainWallets = payload;
			state.loading = 'WAIT';
		});
		builder.addCase(getWallets.rejected, (state) => {
			state.loading = 'WAIT';
		});

		builder.addCase(createWallet.pending, (state) => {
			state.loading = 'CREATE_WALLET';
		});
		builder.addCase(createWallet.fulfilled, (state, { payload }) => {
			state.loading = 'WAIT';
		});
		builder.addCase(createWallet.rejected, (state) => {
			state.loading = 'WAIT';
		});
	},
});

export const adminReducer = adminSlice.reducer;
export const { resetUsers } = adminSlice.actions;

export const getUsers = createAsyncThunk<
	IAdminUsersResponse,
	IGetManyUsers,
	AsyncThunkConfig
>('admin/getUsers', async (data, { rejectWithValue }) => {
	try {
		return await adminService.getUsers(data);
	} catch (error) {
		return rejectWithValue('[ERROR]: admin/getUsers');
	}
});

export const getUser = createAsyncThunk<IAdminUserFull, number, AsyncThunkConfig>(
	'admin/getUser',
	async (id, { rejectWithValue }) => {
		try {
			return await adminService.getUser(id);
		} catch (error) {
			return rejectWithValue('[ERROR]: admin/getUser');
		}
	}
);

export const getOutputRequests = createAsyncThunk<
	IOutputRequest[],
	void,
	AsyncThunkConfig
>('admin/getOutputRequests', async (_, { rejectWithValue }) => {
	try {
		return await adminService.getOutputRequests();
	} catch (error) {
		return rejectWithValue('[ERROR]: admin/getOutputRequests');
	}
});

export const confirmOutputRequests = createAsyncThunk<
	IOutputRequest,
	IConfirmOutputRequest,
	AsyncThunkConfig
>('admin/confirmOutputRequests', async (data, { rejectWithValue }) => {
	try {
		return await adminService.confirmOutputRequests(data);
	} catch (error) {
		return rejectWithValue('[ERROR]: admin/confirmOutputRequests');
	}
});

export const getUpcomingFinish = createAsyncThunk<
	IUpcomingFinish,
	void,
	AsyncThunkConfig
>('admin/getUpcomingFinish', async (_, { rejectWithValue }) => {
	try {
		return await adminService.getUpcomingFinish();
	} catch (error) {
		return rejectWithValue('[ERROR]: admin/getUpcomingFinish');
	}
});

export const getWallets = createAsyncThunk<IMainWallet[], void, AsyncThunkConfig>(
	'admin/getWallets',
	async (_, { rejectWithValue }) => {
		try {
			return await adminService.getWallets();
		} catch (error) {
			return rejectWithValue('[ERROR]: admin/getWallets');
		}
	}
);

export const createWallet = createAsyncThunk<void, void, AsyncThunkConfig>(
	'admin/createWallet',
	async (_, { rejectWithValue, dispatch }) => {
		try {
			await adminService.createWallet();
			await dispatch(getWallets());
		} catch (error) {
			return rejectWithValue('[ERROR]: admin/createWallet');
		}
	}
);
