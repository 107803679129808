import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { AsyncThunkConfig } from 'common/interfaces';
import { holdingService } from 'services/services';
import { ICreateHolding, IHolding } from 'services/services.interface';
import { IHoldingStats } from '../../services/services.interface';

interface IHoldingInitialState {
	holdings: IHolding[];
	history: IHolding[];
	stats: IHoldingStats | null;
}

const initialState: IHoldingInitialState = {
	holdings: [],
	history: [],
	stats: null,
};

export const holdingSlice = createSlice({
	name: 'holding',
	initialState,
	reducers: {
		clearHoldings(state) {
			state.holdings = [];
		},
	},
	extraReducers: (builder) => {
		builder.addCase(getHoldings.fulfilled, (state, { payload }) => {
			state.holdings = payload;
		});
		builder.addCase(getHoldingsHistory.fulfilled, (state, { payload }) => {
			state.history = payload;
		});
		builder.addCase(getHoldingsStats.fulfilled, (state, { payload }) => {
			state.stats = payload;
		});
	},
});

export const holdingReducer = holdingSlice.reducer;
export const { clearHoldings } = holdingSlice.actions;

export const getHoldings = createAsyncThunk<IHolding[], void, AsyncThunkConfig>(
	'holding/getHoldings',
	async (_, { rejectWithValue }) => {
		try {
			return await holdingService.getActive();
		} catch (error) {
			return rejectWithValue('[getHoldings]: Error');
		}
	}
);

export const getHoldingsHistory = createAsyncThunk<IHolding[], void, AsyncThunkConfig>(
	'holding/getHoldingsHistory',
	async (_, { rejectWithValue }) => {
		try {
			return await holdingService.getHistory();
		} catch (error) {
			return rejectWithValue('[getHoldingsHistory]: Error');
		}
	}
);

export const getHoldingsStats = createAsyncThunk<
	IHoldingStats,
	number | undefined,
	AsyncThunkConfig
>('holding/getHoldingsStats', async (days, { rejectWithValue }) => {
	try {
		return await holdingService.getStats(days);
	} catch (error) {
		return rejectWithValue('[getHoldingsStats]: Error');
	}
});

export const createHolding = createAsyncThunk<IHolding, ICreateHolding, AsyncThunkConfig>(
	'holding/createHolding',
	async (data, { rejectWithValue }) => {
		try {
			return await holdingService.create(data);
		} catch (error) {
			return rejectWithValue('[createHolding]: Error');
		}
	}
);
