import { Suspense, useEffect } from 'react';
import { RouterProvider } from 'react-router-dom';
import {
	bindThemeParamsCSSVars,
	initInitData,
	initMiniApp,
	initThemeParams,
} from '@telegram-apps/sdk';
import eruda from 'eruda';
// TODO - del t.me/sdk

import { useAppDispatch, useAppSelector } from 'common/hooks';
import { initialize } from 'store/reducers/appSlice';
import { router } from 'router/router';
import { Loader } from 'components';

const App = () => {
	const dispatch = useAppDispatch();
	const { isAppInitialized } = useAppSelector((state) => state.app);
	const { notice } = useAppSelector((state) => state.app);

	useEffect(() => {
		// if (process.env.REACT_APP_API_URL?.includes('ngrok'))
		eruda.init();

		let userId = 0;
		try {
			const data = initInitData();
			userId = data?.user?.id || 0;
			dispatch(initialize({ tgId: userId, username: data?.user?.username }));

			const tp = initThemeParams()[0];
			bindThemeParamsCSSVars(tp);
			const isDark = tp.isDark;
			document.documentElement.setAttribute('data-theme', isDark ? 'dark' : 'light');

			const miniApp = initMiniApp()[0];
			miniApp.setHeaderColor('secondary_bg_color');
			miniApp.setBgColor(tp.secondaryBgColor || '#000');
		} catch {}
	}, [dispatch]);

	if (!isAppInitialized) {
		return (
			<div
				style={{
					position: 'fixed',
					top: 0,
					left: 0,
					bottom: 0,
					right: 0,
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
				}}>
				<Loader />
			</div>
		);
	}

	return (
		<div
			style={{
				padding: '24px 16px',
				margin: '0 auto',
				overflow: 'hidden',
			}}>
			<Suspense fallback={<Loader />}>
				<RouterProvider router={router} />
				<div style={{ position: 'fixed', top: '10px', left: '10px' }}>
					{/* {notice?.message} */}
				</div>
			</Suspense>
		</div>
	);
};

export default App;
