import { SVGProps } from 'react';

const TransactionsIcon = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={25}
		height={24}
		viewBox="0 0 25 24"
		fill="none"
		{...props}>
		<path
			stroke="var(--hint-color)"
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeMiterlimit={10}
			strokeWidth={1.5}
			d="M12.396 5.56h10.197"
		/>
		<path
			stroke="var(--hint-color)"
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth={1.5}
			d="M14.66 2h5.67c1.815 0 2.263.44 2.263 2.2v4.11c0 1.76-.448 2.2-2.264 2.2h-5.67c-1.815 0-2.264-.44-2.264-2.2V4.2c0-1.76.45-2.2 2.264-2.2Z"
		/>
		<path
			stroke="var(--hint-color)"
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeMiterlimit={10}
			strokeWidth={1.5}
			d="M2.197 17.06h10.198"
		/>
		<path
			stroke="var(--hint-color)"
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth={1.5}
			d="M4.461 13.5h5.67c1.815 0 2.264.44 2.264 2.2v4.11c0 1.76-.449 2.2-2.264 2.2h-5.67c-1.815 0-2.264-.44-2.264-2.2V15.7c0-1.76.449-2.2 2.264-2.2ZM22.593 15c0 3.87-3.192 7-7.139 7l1.07-1.75M2.197 9c0-3.87 3.192-7 7.139-7L8.265 3.75"
		/>
	</svg>
);
export default TransactionsIcon;
