import { SVGProps } from 'react';

const CloseIcon = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={24}
		height={24}
		viewBox="0 0 24 24"
		fill="none"
		{...props}>
		<path
			stroke="var(--text-color)"
			strokeLinecap="round"
			strokeWidth={1.5}
			d="M3 21 21 3M21 21 3 3"
		/>
	</svg>
);
export default CloseIcon;
