import { FC, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from 'common/hooks';
import { setBackBtn } from 'common/utils';

import styles from './Strategy.module.scss';
import { CircleInfoIcon, PlusIcon } from 'icons';
import { getStrategies } from 'store/reducers/strategySlice';
import { ActionPopup, Button, Input } from 'components';
import { createHolding } from 'store/reducers/holdingSlice';
import { checkWallet } from 'store/reducers/userSlice';

const Strategy: FC = () => {
	const { id } = useParams();
	const { user, wallet } = useAppSelector((state) => state.user);
	const { strategies } = useAppSelector((state) => state.strategy);
	const strategy = strategies.find((el) => `${el.id}` === id);

	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	setBackBtn(() => navigate('/'));

	useEffect(() => {
		dispatch(getStrategies());
		dispatch(checkWallet());
	}, []);

	const [isLaunched, setIsLaunched] = useState(false);
	const [value, setValue] = useState('');
	const [error, setError] = useState('');

	useEffect(() => {
		if (!strategy) {
			return;
		}

		setError('');

		if (value === '' || !strategy) {
			return;
		}
		if (!value.match(/^[0-9]+([.,][0-9]*)?$/g)) {
			setError('Type numbers only');
			return;
		}

		const numValue = +value;
		if (numValue < strategy.minDeposit) {
			setError(`The minimum amount is ${strategy.minDeposit} USDT`);
			return;
		}
		if (numValue > strategy.maxDeposit) {
			setError(`The maximum amount is ${strategy.maxDeposit} USDT`);
			return;
		}
	}, [value]);

	const onSend = async () => {
		if (value === '' || !strategy) return;

		const actionResult = await dispatch(
			createHolding({
				strategyId: strategy.id,
				userId: user.id,
				sum: +value,
			})
		);

		if (createHolding.fulfilled.match(actionResult)) {
			setIsLaunched(true);
		}
	};

	const onActionPopupClose = () => {
		setIsLaunched(false);
		setError('');
		setValue('');
	};

	if (!strategy) return <></>;

	return (
		<div className={styles.wrapper}>
			<div className={styles.title}>Available Strategy</div>
			<div className={styles.content}>
				<div className={styles.name}>
					<div className={styles.image}>
						{strategy.image && (
							<img
								src={process.env.REACT_APP_API_URL + `/uploads/${strategy.image}`}
								onError={(e) => {
									e.currentTarget.style.display = 'none';
								}}
							/>
						)}
					</div>
					{strategy.name}
				</div>
				<div className={styles.rate}>+{((strategy.rate - 1) * 100).toFixed(2)}%</div>
				<div>
					<div className={styles.info_label}>Placement period:</div>
					<p className={styles.date}>{strategy.days}</p>
				</div>
				<div>
					<div className={styles.info_label}>Deposit:</div>
					<div className={styles.dep}>
						from {strategy.minDeposit} to {strategy.maxDeposit} USDT
					</div>
				</div>
			</div>

			<div className={styles.info}>
				<CircleInfoIcon />
				<div>
					You will not be able to withdraw money from the strategy for {strategy.days}{' '}
					days.
				</div>
			</div>

			<div className={styles.form_wrapper}>
				<div className={styles.title} style={{ textAlign: 'left' }}>
					Enter the deposit amount
				</div>
				<div className={styles.form}>
					<Input
						className={styles.input}
						placeholder="Amount"
						type="number"
						pattern="[0-9]*"
						inputMode="decimal"
						rightLabel="USDT"
						error={error}
						min={strategy.minDeposit}
						max={strategy.maxDeposit}
						value={value}
						onChange={(e) => setValue(e.target.value)}
					/>
					<div className={styles.balance}>
						<div className={styles.balance_label}>Your Balance</div>
						<div
							onClick={() => {
								navigate('/payment');
							}}
							className={styles.balance_value}>
							<PlusIcon />
							{wallet.availableSum.toFixed(2)} USDT
						</div>
					</div>
					<Button
						disabled={error !== '' || value === ''}
						size="small"
						onClick={onSend}
						className={styles.button}>
						Send
					</Button>
					<div className={styles.send_block_info}>
						By making a deposit, you agree to the offer
					</div>
				</div>
			</div>
			<ActionPopup show={isLaunched} onClose={onActionPopupClose}>
				<div className={styles.popup}>
					<div className={styles.popup_image}>
						{strategy.image && (
							<img
								src={process.env.REACT_APP_API_URL + `/uploads/${strategy.image}`}
								onError={(e) => {
									e.currentTarget.style.display = 'none';
								}}
							/>
						)}
					</div>
					<div className={styles.popup_info}>
						<div className={styles.popup_title}>Successfully launched</div>
						<p className={styles.popup_text}>
							<span className={styles.amount}>{value} USDT</span> was launched into the{' '}
							<span className={styles.tool_name}>{strategy.name}</span> strategy for{' '}
							<span className={styles.days}>{strategy.days}</span> days
						</p>
					</div>
					<Button onClick={onActionPopupClose} size="small" className={styles.popup_btn}>
						Okey
					</Button>
				</div>
			</ActionPopup>
		</div>
	);
};

export default Strategy;
