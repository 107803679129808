import { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { checkWallet } from 'store/reducers/userSlice';
import { useAppDispatch, useAppSelector } from 'common/hooks';
import { Info, Button, Strategies } from 'components';
import { AddIcon, SendIcon, TransactionsIcon, WalletCheckIcon } from 'icons';
import { getStrategies } from 'store/reducers/strategySlice';

import styles from './Main.module.scss';

const Main: FC = () => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	useEffect(() => {
		const interval = setInterval(() => {
			dispatch(checkWallet());
		}, 60 * 1000);

		return () => {
			clearInterval(interval);
		};
	}, []);

	const { user, wallet } = useAppSelector((state) => state.user);

	return (
		<div className={styles.wrapper}>
			<button
				className={styles.transactions_btn}
				onClick={() => navigate('/transactions')}>
				<TransactionsIcon />
			</button>
			<div className={styles.content}>
				<div className={styles.header}>
					<div className={styles.balance}>
						<p>Balance</p>
						<div className={styles.balance_value}>
							<div className={styles.sum}>
								<span>$</span> {wallet.availableSum.toFixed(2)}
							</div>
						</div>
					</div>
					<div className={styles.buttons}>
						<Button onClick={() => navigate('/payment')} className={styles.button}>
							<AddIcon />
							Add Crypto
						</Button>
						<Button onClick={() => navigate('/send')} className={styles.button}>
							<SendIcon />
							Send
						</Button>
						<Button
							color="white"
							onClick={() => navigate('/holdings')}
							className={styles.button}>
							<WalletCheckIcon />
							My Strategies
						</Button>
					</div>
				</div>
				<Info />
				<Strategies />
				{user.role === 'MAIN' && (
					<button onClick={() => navigate('/admin')} className={styles.admin_button}>
						Админка
					</button>
				)}
			</div>
		</div>
	);
};

export default Main;
