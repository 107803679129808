import { FC, useEffect, useRef } from 'react';
import { initThemeParams } from '@telegram-apps/sdk-react';
import QRCodeStyling from 'qr-code-styling';

import usdtImg from 'assets/img/usdt.svg';
import styles from './QrCode.module.scss';

const qrCode = new QRCodeStyling({
	width: 200,
	height: 200,
	image: usdtImg,
	type: "svg",
	dotsOptions: {
		type: 'extra-rounded',
	},
	cornersSquareOptions: {
		type: 'extra-rounded',
	},
	imageOptions: {
		crossOrigin: 'anonymous',
		margin: 2,
		imageSize: 0.4,
	},
});

interface IQrCode {
	address: string;
}

const QrCode: FC<IQrCode> = ({ address }) => {
	const ref = useRef<HTMLDivElement | null>(null);

	useEffect(() => {
		let dotColor = 'black';
		try {
			const tp = initThemeParams()[0];
			tp.on('change', () => {
				const newTp = initThemeParams()[0];
				qrCode.update({
					dotsOptions: {
						color: newTp.textColor || dotColor,
					},
				});
			});

			dotColor = tp.textColor || dotColor;
		} catch {}

		qrCode.append(ref.current || undefined);

		qrCode.update({
			data: address,
			backgroundOptions: {
				color: 'transparent',
			},
			image: usdtImg,
			dotsOptions: {
				color: dotColor,
			},
		});
	}, [address]);

	return (
		<div className={styles.wrapper}>
			<div ref={ref} />
		</div>
	);
};

export default QrCode;
