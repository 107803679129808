import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { AsyncThunkConfig } from 'common/interfaces';
import { settingsService } from 'services/services';
import { ISettings } from 'services/services.interface';

interface ISettingsInitialState {
	settings: ISettings<number>[];
}

const initialState: ISettingsInitialState = {
	settings: [],
};

export const settingsSlice = createSlice({
	name: 'settings',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(getSettings.fulfilled, (state, { payload }) => {
			state.settings = payload;
		});
	},
});

export const settingsReducer = settingsSlice.reducer;
// export const { } = settingsSlice.actions;

export const getSettings = createAsyncThunk<ISettings<number>[], void, AsyncThunkConfig>(
	'settings/getSettings',
	async (_, { rejectWithValue }) => {
		try {
			return await settingsService.getAll();
		} catch (error) {
			return rejectWithValue('[ERROR]: settings/getSettings');
		}
	}
);

export const setSettings = createAsyncThunk<void, ISettings<number>[], AsyncThunkConfig>(
	'settings/setSettings',
	async (data, { rejectWithValue, dispatch }) => {
		try {
			for (const el of data) {
				await settingsService.update(el.name, el.value.toString());
			}
			await dispatch(getSettings());
		} catch (error) {
			return rejectWithValue('[ERROR]: settings/setSettings');
		}
	}
);
