import { FC, useEffect, useState } from 'react';

import { useAppDispatch, useAppSelector } from 'common/hooks';
import { getSettings, setSettings } from 'store/reducers/settingsSlice';
import { ISettings } from 'services/services.interface';
import { Button, Input } from 'components';

import styles from './AdminSettings.module.scss';
import { SettingsType } from '../../../services/services.interface';
import { useNavigate } from 'react-router-dom';
import { setBackBtn } from 'common/utils';

const names: { [key in SettingsType]: string } = {
	'min-holding-notification': 'Уведомлять стратегию от',
	'output-fee': 'Комиссия вывода',
	'max-output-without-admin': 'Максиимальная сумма вывод без подтверждения',
};

interface IAdminSettings {}

const AdminSettings: FC<IAdminSettings> = ({}) => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	setBackBtn(() => navigate('/admin'));

	const [changed, setChanged] = useState(false);
	const [data, setData] = useState<ISettings<number>[]>([]);
	const { settings } = useAppSelector((state) => state.settings);

	useEffect(() => {
		dispatch(getSettings());
	}, []);

	useEffect(() => {
		setData(settings);
	}, [settings]);

	useEffect(() => {
		let isChanged = false;
		for (const el of data) {
			const current = settings.find((cur) => cur.name === el.name);
			if (current?.value !== el.value) {
				isChanged = true;
			}
		}

		setChanged(isChanged);
	}, [data]);

	const sendChange = () => {
		dispatch(setSettings(data));
	};

	return (
		<div className={styles.wrapper}>
			<div className={styles.title}>Настройки</div>
			<div className={styles.content}>
				{data.map((el) => (
					<div key={el.name} className={styles.item}>
						<div className={styles.label}>{names[el.name]}</div>
						<Input
							onChange={(e) => {
								e.preventDefault();
								setData((prev) =>
									prev.map((prevEl) => {
										if (el.name === prevEl.name) {
											let value = e.target.value;
											if (value.length > 1 && value[0] === '0' && !isNaN(+value[1])) {
												value = value.slice(1);
											}

											return {
												name: prevEl.name,
												value: +value,
											};
										} else return prevEl;
									})
								);
							}}
							className={styles.label}
							value={Number(el.value).toString()}
							rightLabel="USDT"
							type="number"
							pattern="[0-9]*"
							inputMode="decimal"
						/>
					</div>
				))}
				{changed && <Button onClick={sendChange}>Сохранить</Button>}
			</div>
		</div>
	);
};

export default AdminSettings;
