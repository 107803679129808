import React from 'react';
import { AppRoot } from '@telegram-apps/telegram-ui';
import { SDKProvider } from '@telegram-apps/sdk-react';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom/client';

import { store } from 'store/createStore';
import App from './App';

import '@telegram-apps/telegram-ui/dist/styles.css';
import './index.scss';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
	<Provider store={store}>
		<SDKProvider acceptCustomStyles debug>
			<App />
		</SDKProvider>
	</Provider>
);
