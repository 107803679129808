import { initBackButton } from '@telegram-apps/sdk-react';

export const setBackBtn = (func: () => void) => {
	try {
		const backButton = initBackButton()[0];
		backButton.show();
		backButton.on('click', () => {
			func();
			backButton.hide();
		});
	} catch {}
};
