import { FC, useEffect, useState } from 'react';
import cn from 'classnames';
import { Link, useNavigate } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from 'common/hooks';
import { getUsers, resetUsers } from 'store/reducers/adminSlice';
import { setBackBtn } from 'common/utils';

import styles from './AdminUsers.module.scss';
import { Loader } from 'components';

const AdminUsers: FC = () => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	const LIMIT = 10;
	const [page, setPage] = useState(0);
	const [isLoading, setLoading] = useState(true);
	const [fetch, setFetch] = useState(false);

	setBackBtn(() => {
		navigate('/admin');
	});

	const { users, usersCount } = useAppSelector((state) => state.admin);

	const fetchUsers = async () => {
		setLoading(true);
		await dispatch(getUsers({ limit: LIMIT, page: page + 1 }));
		setPage((prev) => prev + 1);
		setLoading(false);
		setFetch(false);
	};

	useEffect(() => {
		console.log(!isLoading);

		if (fetch && !isLoading && usersCount !== users.length) {
			fetchUsers();
		}
	}, [fetch]);

	useEffect(() => {
		fetchUsers();

		const onScrollHandler = async () => {
			if (document.body.scrollHeight - (window.scrollY + window.outerHeight) < 50)
				setFetch(true);
		};

		document.addEventListener('scroll', onScrollHandler);
		return () => {
			document.removeEventListener('scroll', onScrollHandler);
			dispatch(resetUsers());
		};
	}, []);

	return (
		<div className={styles.wrapper}>
			<div className={styles.title}>Users</div>
			<div className={styles.list}>
				{users.map((u) => (
					<div key={u.id} className={styles.item}>
						<div className={styles.item_head}>
							<Link className={styles.id} to={`/admin/user/${u.id}`}>
								id: {u.id}
							</Link>
							<div className={styles.date}>
								{new Date(u.createdAt).toLocaleDateString()}
							</div>
						</div>
						<div className={styles.item_info}>
							<div className={styles.label}>Текущий баланс:</div>
							<div className={styles.value}>{u.walletSum.toFixed(2)} $</div>
						</div>
						<div className={styles.item_info}>
							<div className={styles.label}>В стратегиях:</div>
							<div className={styles.value}>{u.holdingValue.toFixed(2)} $</div>
						</div>
						<br />
						<div className={styles.item_info}>
							<div className={styles.label}>Пополнений:</div>
							<div className={styles.value}>{u.inputValue.toFixed(2)} $</div>
						</div>
						<div className={styles.item_info}>
							<div className={styles.label}>Вывод:</div>
							<div className={styles.value}>{u.outputValue.toFixed(2)} $</div>
						</div>
						<div className={styles.item_info}>
							<div className={styles.label}>Заработано:</div>
							<div className={cn(styles.value, styles.green)}>
								{u.earningsValue.toFixed(2)} $
							</div>
						</div>
					</div>
				))}
			</div>
			{isLoading && (
				<div className={styles.loader}>
					<Loader size="small" />
				</div>
			)}
		</div>
	);
};

export default AdminUsers;
