import { FC } from 'react';
import { Outlet } from 'react-router-dom';

import { useAdminGuard } from 'common/hooks';

const AdminLayout: FC = () => {
	const isAdmin = useAdminGuard();

	if (!isAdmin) return <></>;

	return <Outlet />;
};

export default AdminLayout;
