import { SVGProps } from 'react';

const SendIcon = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={25}
		height={24}
		viewBox="0 0 25 24"
		fill="none"
		{...props}>
		<path
			fill="var(--btn-text-color)"
			fillRule="evenodd"
			d="M12.499 21a9 9 0 1 0 0-18 9 9 0 0 0 0 18Zm.75-5a.75.75 0 0 1-1.5 0V9.81l-2.22 2.22a.75.75 0 0 1-1.06-1.06l3.5-3.5a.75.75 0 0 1 1.06 0l3.5 3.5a.75.75 0 0 1-1.06 1.06l-2.22-2.22V16Z"
			clipRule="evenodd"
		/>
	</svg>
);
export default SendIcon;
